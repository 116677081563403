import { Controller } from "stimulus"
import find from "lodash/find"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["modal", "container", "form", "field"]
  
  async load(e) {
    if (e.currentTarget.hasAttribute("disabled")) {
      e.preventDefault()
    } else {
      this.renew()
      const response = await fetch(this.url)
      this.render(await response.text())
    }
  }
  
  render(html) {
    this.currentContainer.innerHTML = html
  }
  
  renew() {
    this.fieldTargets.forEach(field => field.value = "")
  }
  
  preventSubmit(e) {
    e.preventDefault()
  }
  
  submit(e) {
    e.preventDefault()
    this.isLoading(e)
    const data = new FormData(this.currentForm)
    Rails.ajax({
      url: this.currentForm.action,
      type: this.formMethod,
      data: data,
      success: this.onComplete.bind(this)
    })
  }
  
  onComplete(res) {
    if (res.replacePage) {
      Rails.fire(this.formTarget, "ajax:success", [{}, 200, res])
    } else if (!res.ok) {
      this.render(res.html)
    }
  }
  
  isLoading(e) {
    e.currentTarget.classList.add("is-loading")
  }
  
  get url() {
    return JSON.parse(this.currentModal.dataset.modalData).url
  }
  
  get formMethod() {
    return this._method ? this._method.value : this.currentForm.method
  }
  
  get _method() {
    return this.currentForm.elements._method
  }
  
  get currentModal() {
    return find(this.modalTargets, modal => modal.classList.contains("is-active"))
  }
  
  get currentForm() {
    return this.currentModal.querySelector("[data-target='modalForm.form']")
  }
  
  get currentContainer() {
    return this.currentModal.querySelector("[data-target='modalForm.container']")
  }
  
}