import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["message"]
  
  initialize() {
    this.outTimers = {}
    this.messageTargets.forEach(this.initializeMessage.bind(this))
  }
  
  triggerRemove(e) {
    const key = e.currentTarget.dataset.key
    this.messageTargets.forEach(msg => {
      if (msg.dataset.key === key) {
        this.remove(msg)
      }
    })
  }
  
  add(e) {
    const msg = this.createMessageElement(e.detail)
    this.initializeMessage(msg)
    this.element.appendChild(msg)
  }
  
  remove(msg, delay = 200) {
    let timer;
    if (timer = this.outTimers[msg.dataset.key]) {
      clearTimeout(timer)
    }
    this.outTimers[msg.dataset.key] = setTimeout(() => {
      msg.classList.add("out")
      setTimeout(() => {this.element.removeChild(msg)}, 250)
    }, delay)
  }
  
  initializeMessage(msg) {
    setTimeout(() => {
      msg.classList.add("in")
      this.remove(msg, 10000)
    }, 250)
  }
  
  createMessageElement({type, key, message}) {
    const el = document.createElement('div')
    el.classList.add(`flash_${type}`)
    el.classList.add('level-item')
    el.setAttribute('data-target', 'flash.message')
    el.setAttribute('data-key', key)
    el.innerHTML = `
      <div class="tags has-addons">
        <div class="tag is-large ${type} flash-message">${message}</div>
        <a class="tag is-large is-delete ${type} is-dark" data-action="click->flash#triggerRemove" data-key="${key}"></a>
      </div>`
    return el
  }
}