import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["info"]
  
  connect() {
    if (this.shouldPosition) {
      this.setPosition()
    }
  }
  
  setPosition() {
    this.reset()
    const rect = this.element.getBoundingClientRect()
    if (rect.left < this.thresholdX) {
      this.infoTarget.classList.add("right")
    } else if (window.innerHeight - rect.bottom < this.thresholdY) {
      this.infoTarget.classList.add("top")
    } else if (window.innerWidth - rect.right < this.thresholdX) {
      this.infoTarget.classList.add("left")
    } else {
      this.infoTarget.classList.add("bottom")
    }
  }
  
  reset() {
    ["bottom", "left", "top", "right"].forEach(klass => {
      if (this.infoTarget.classList.contains(klass)) {
        this.infoTarget.classList.remove(klass)
      }
    })
  }
  
  get shouldPosition() {
    return !('noPosition' in this.element.dataset)
  }
  
  get thresholdX() {
    if (this.infoTarget.classList.contains('is-wide')) {
      return 200
    } else {
      return 155
    }
  }
  /*
  * would be nice to base this in height of displayed infoBox.
  * however, `this.infoTarget.getBoundingClientRect()` will only
  * return helpful data while it is being shown. Which is too late.
  */
  get thresholdY() {
    return 100
  }
}