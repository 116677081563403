import { Controller } from "stimulus"
import find from "lodash/find"

export default class extends Controller {
  static targets = ["dialog"]
  
  initialize() {
    const params = this.urlParams
    if (params.has("prompt")) {
      this._open(params.get("prompt"))
    }
  }
  
  open(e) {
    if (e.currentTarget.hasAttribute("disabled")) {
      e.preventDefault()
    } else {
      this._open(e.currentTarget.dataset.modalTarget, e.currentTarget)
    }
  }
  
  _open(name, dataSource) {
    const dialog = this.dialogByName(name)
    if (dialog) {
      dialog.classList.add("is-active")
    }
    if (dataSource) {
      this.transferData(dialog, dataSource)
    }
  }
  
  remoteClose(e) {
    this.teardown(e.detail.name)
  }
  
  close(e) {
    this.teardown(e.currentTarget.dataset.modalTarget)
  }
  
  teardown(name) {
    const dialog = this.dialogByName(name)
    if (dialog) {
      dialog.classList.remove("is-active")
      this.clearData(dialog)
    }
  }
  
  triggerCallback(e) {
    const [controller, callback] = this.dataModalCallback(e)
    const dialog = this.dialogByName(e.currentTarget.dataset.modalTarget)
    this.dialogController(dialog, controller)[callback]()
  }
  
  transferData(dialog, target) {
    if ("modalData" in target.dataset) {
      dialog.dataset.modalData = target.dataset.modalData
    }
  }
  
  clearData(dialog) {
    if ("modalData" in dialog.dataset) {
      delete dialog.dataset.modalData
    }
  }
  
  dialogByName(name) {
    return find(this.dialogTargets, function(dialog) {
      return dialog.dataset.modalName === name
    })
  }
  
  dialogController(el, name) {
    return this.application.getControllerForElementAndIdentifier(el, name)
  }
  
  dataModalCallback(event) {
    return event.currentTarget.dataset.modalCallback.split('#')
  }
  
  get urlParams() {
    return new URLSearchParams(window.location.search)
  }
}