import { Controller } from "stimulus"
import _cap from "lodash/capitalize"
export default class extends Controller {
  
  update(e) {
    if (e.detail.status && this.data.get('id') === e.detail.section) {
      this.element.classList.add("re-rendering")
      setTimeout(() => {
        this.element.classList.remove("re-rendering", "is-reported","is-acknowledged","is-discussed","is-investigated")
        this.element.classList.add(`is-${e.detail.status}`, "in")
        this.element.innerHTML = _cap(e.detail.status)
      }, 250)
    }
  }
  
  
}