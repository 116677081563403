import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source", "field"]
  
  initialize() {
    this.update()
  }
  
  async update() {
    if (this.hasSourceTarget && 0 < this.sourceTarget.value.length) {
      const response = await fetch(this.path)
      const data = await response.json()
      this.setFields(data)
    }
  }
  
  setFields(data) {
    this.fieldTargets.forEach(field => {
      const value = data[field.dataset.sourceAttribute]
      if (value && 0 < value.length) {
        field.value = value
      } else {
        field.value = ""
      }
    })
  }
  
  get path() {
    return this.sourceTarget.dataset.url + '?' + this.sourceTarget.dataset.paramName + '=' + this.sourceTarget.value
  }
}