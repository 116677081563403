import { Controller } from "stimulus"

export default class extends Controller {
  preventDefault(e) {
    e.preventDefault()
  }
  
  isLoading(e) {
    e.currentTarget.classList.add("is-loading")
  }
  
  broadcast(e) {
    const eventName = e.currentTarget.dataset.eventName
    const detail = e.currentTarget.dataset.eventDetail
    const event = new CustomEvent(eventName, {detail: detail})
    document.dispatchEvent(event)
  }
}