import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["container", "form"]
  
  render(html) {
    this.containerTarget.innerHTML = html
  }
  
  preventSubmit(e) {
    e.preventDefault()
  }
  
  submit(e) {
    e.preventDefault()
    this.isLoading(e)
    const data = new FormData(this.formTarget)
    Rails.ajax({
      url: this.formTarget.action,
      type: this.formMethod,
      data: data,
      success: this.onErrors.bind(this)
    })
  }
  
  onErrors(res) {
    if (res.replacePage) {
      Rails.fire(this.formTarget, "ajax:success", [{}, 200, res])
    } else {
      this.render(res.html)
    }
  }
  
  isLoading(e) {
    e.currentTarget.classList.add("is-loading")
  }
  
  get formMethod() {
    return this._method ? this._method.value : this.formTarget.method
  }
  
  get _method() {
    return this.formTarget.elements._method
  }
}