import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    const email = this.element.firstElementChild
    if (email.hasAttribute("style") && email.classList.contains("email-body")) {
      this.element.classList.add("email-wrapper")
    } else {
      this.element.classList.add("email-body")
    }
  }
}