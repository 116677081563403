import { Controller } from "stimulus"
import {loadStripe} from "@stripe/stripe-js";
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["form", "plan"]
  
  initialize() {
    loadStripe(this.publicKey).then(res => {
      this.stripe = res;
    })
  }
  
  checkout(e) {
    e.preventDefault()
    this.isLoading(e)
    Rails.ajax({
      url: this.data.get("path"),
      type: this.requestMethod,
      data: this.formData(e),
      success: this.stripe.redirectToCheckout
    })
  }
  
  isLoading(e) {
    e.currentTarget.classList.add("is-loading")
  }
  
  formData(e) {
    const form = new FormData()
    form.append("authenticity_token", this.data.get('authToken'))
    form.append("plan", e.currentTarget.dataset.plan)
    return form
  }
  
  get publicKey() {
    return document.getElementsByName('stripe_public_key')[0].content
  }
  
  get requestMethod() {
    if (this.data.has("requestMethod")) {
      return this.data.get("requestMethod")
    } else {
      return "PATCH"
    }
  }
}