import { Controller } from "stimulus"
import _indexOf from "lodash/indexOf"
import { details, icons, steps } from "../services/incident_steps.js"

export default class extends Controller {
  static targets = ["step", "action", "nextStep", "current"]
  
  connect() {
    this.update(this.data.get("current"))
  }
  
  setCurrent(e) {
    if (e.detail.status && this.sectionId === e.detail.section && this.currentStep !== e.detail.status) {
      this.data.set("current", e.detail.status)
      this.update(e.detail.status)
    }
  }
  
  update(status) {
    this.setNextStep(status)
    if (status == "reported") {
      this.setCompleted(this.targetStep("reported"))
      this.setActive(this.targetStep("acknowledged"))
      this.setDefault(this.targetStep("discussed"))
      this.setDefault(this.targetStep("investigated"))
      
    } else if (status == "acknowledged") {
      this.setCompleted(this.targetStep("reported"))
      this.setCompleted(this.targetStep("acknowledged"))
      this.setActive(this.targetStep("discussed"))
      this.setDefault(this.targetStep("investigated"))
      
    } else if (status == "discussed") {
      this.setCompleted(this.targetStep("reported"))
      this.setCompleted(this.targetStep("acknowledged"))
      this.setCompleted(this.targetStep("discussed"))
      this.setActive(this.targetStep("investigated"))
      
    } else if (status == "investigated") {
      this.setCompleted(this.targetStep("reported"))
      this.setCompleted(this.targetStep("acknowledged"))
      this.setCompleted(this.targetStep("discussed"))
      this.setCompleted(this.targetStep("investigated"))
      
    }
  }
  
  targetStep(status) {
    return this.stepTargets[_indexOf(steps, status)]
  }
  
  setDefault(target) {
    if (target) {
      target.classList.remove("is-active")
      if (target.classList.contains("is-completed")) {
        target.classList.remove("is-completed")
        target.classList.remove("is-success")
        target.querySelector(".step-marker").innerHTML = icons[target.dataset.step]
      }
    }
  }
  
  setActive(target) {
    if (target) {
      target.classList.add("is-active")
      if (target.classList.contains("is-completed")) {
        target.classList.remove("is-completed")
        target.classList.remove("is-success")
        target.querySelector(".step-marker").innerHTML = icons[target.dataset.step]
      }
    }
  }
  
  setCompleted(target) {
    if (target) {
      target.classList.remove("is-active")
      this.currentTarget.innerHTML = details[target.dataset.step]
      if (!target.classList.contains("is-completed")) {
        target.classList.add("is-completed")
        target.classList.add("is-success")
        target.querySelector(".step-marker").innerHTML = icons.completed
      }
    }
  }
  
  setNextStep(status) {
    const lastIndex = steps.length - 1
    const nextIndex = _indexOf(steps, status) + 1
    if (nextIndex <= lastIndex) {
      this.actionTarget.removeAttribute("disabled")
      this.actionTarget.className = `button is-${steps[nextIndex]}`
      this.nextStepTarget.innerHTML = steps[nextIndex]
    } else {
      this.actionTarget.setAttribute("disabled", "")
      this.actionTarget.className = `button is-${steps[lastIndex]}`
      this.nextStepTarget.innerHTML = steps[lastIndex]
    }
  }
  
  get sectionId() {
    return this.data.get("id")
  }
  
  get currentStep() {
    return this.data.get("current")
  }
  
  get nextStep() {
    return steps[_indexOf(steps, this.currentStep) + 1]
  }
  
  get incidentId() {
    return this.dataset.incidentId
  }
}