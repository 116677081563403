import { Controller } from "stimulus"

export default class extends Controller {
  
  now(e) {
    if (this.sectionId === e.detail.section) {
      this.requestUpdate()
    }
  }
  
  async requestUpdate() {
    const response = await fetch(this.path)
    const view = await response.text()
    if (this.currentView != view.trim()) {
      this.updateView(view)
    }
  }
  
  updateView(html) {
    this.element.classList.add("re-rendering")
    setTimeout(() => {
      this.element.innerHTML = html
      this.element.classList.remove("re-rendering")
    }, 200)
  }
  
  get path() {
    return this.data.get('path')
  }
  
  get sectionId() {
    return this.data.get('id')
  }
  
  get currentView() {
    return this.element.innerHTML.toString().trim()
  }
}