import { Controller } from "stimulus"
import _every from "lodash/every"

export default class extends Controller {
  static targets = ["required", "action"]
  
  initialize() {
    this.update()
  }
  
  update() {
    if (_every(this.requiredTargets, ['checked', true])) {
      this.actionTarget.removeAttribute("disabled")
    } else {
      this.actionTarget.setAttribute("disabled", "")
    }
  }
}