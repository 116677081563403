const reported = `
<p class="step-title">Reported</p>
<p>You've submitted a report to the school principal.</p>`

const acknowledged = `
<p class="step-title">Acknowledged</p>
<p>The principal has responded to your report.</p>`

const discussed = `
<p class="step-title">Discussed</p>
<p>You've met with the principal and discussed the incident.</p>
`

const investigated = `
<p class="step-title">Investigated</p>
<p>The school has concluded a thorough investigation.</p>
`

export default {
  reported: reported.trim(),
  acknowledged: acknowledged.trim(),
  discussed: discussed.trim(),
  investigated: investigated.trim(),
  completed: reported.trim(),
}