import { Controller } from "stimulus"
import some from "lodash/some"

export default class extends Controller {
  static targets = ["input", "submitButton"]
  
  connect() {
    this.disableSubmit()
    this.originalValues = {}

    setTimeout((/* allow for potential thirdparty javascript input setup */) => {
      this.inputTargets.forEach(target => {
        this.originalValues[target.id] = this.currentValue(target)
      })
    }, 0)
  }
  
  compare() {
    let changes = this.inputTargets.map(target => this.valueChanged(target))
    some(changes) ? this.enableSubmit() : this.disableSubmit()
  }
  
  valueChanged(input) {
    return this.originalValues[input.id] !== this.currentValue(input)
  }
  
  currentValue(input) {
    let current
    if (input.hasAttribute("multiple")) {
      current = this.getSelected(input)
    } else {
      current = input.value
    }
    return current
  }
  
  disableSubmit() {
    this.submitButtonTargets.forEach(button => {
      button.setAttribute("disabled", true)
    })
  }
  
  enableSubmit() {
    this.submitButtonTargets.forEach(button => {
      button.removeAttribute("disabled")
    })
  }
  
  getSelected(select) {
    return select.dataset.multiselectSelected
  }
}