import { Controller } from "stimulus"
import toInteger from "lodash/toInteger"
import lessThan from "lodash/lt"

export default class extends Controller {
  static targets = ["addButton"]
  
  initialize() {
    if (this.addingAllowed) {
      this.enable()
    }
  }
  
  enable() {
    this.addButtonTarget.removeAttribute("disabled")
    this.addButtonTarget.classList.remove("is-disabled")
  }
  
  disable() {
    this.addButtonTarget.addAttribute("disabled", "disabled")
    this.addButtonTarget.classList.add("is-disabled")
  }
  
  get addingAllowed() {
    return lessThan(0, this.canAddCount)
  }
  
  get canAddCount() {
    return toInteger(this.data.get("allowAdd"))
  }
}