const reported = `
<span class="icon">
  <i class="fa fa-check"></i>
</span>`

const acknowledged = `
<span class="icon">
  <i class="fa fa-exclamation"></i>
</span>`

const discussed = `
<span class="icon">
  <i class="fas fa-comments"></i>
</span>`

const investigated = `
<span class="icon">
  <i class="fas fa-eye"></i>
</span>`

export default {
  reported: reported.trim(),
  acknowledged: acknowledged.trim(),
  discussed: discussed.trim(),
  investigated: investigated.trim(),
  completed: reported.trim(),
}