import { Controller } from "stimulus"
import bulmaCalendar from "bulma-calendar/dist/js/bulma-calendar.min.js"

export default class extends Controller {
  static targets = ["input"]
  
  initialize() {
    this.inputTargets.forEach(input => {
      const options = {
        showHeader: false,
        validateLabel: 'Select',
        type: input.dataset.type || 'date',
        displayMode: input.dataset.display || 'default',
      }
      
      if (input.dataset.weekdaysOnly) {
        options.disabledWeekDays = [0,6]
      }
      
      if (input.dataset.limitTo === 'past') {
        options.maxDate = new Date()
      } else if (input.dataset.limitTo === 'future') {
        options.minDate = new Date()
      }
      
      const datepicker = bulmaCalendar.attach(input, options)[0]
      
      if (input.dataset.display !== 'inline') {
        datepicker.on('show', this.onShow.bind(this))
      }
    })
  }
  
  onShow(e) {
    const datepicker = e.data._ui.calendar
    const rect = datepicker.getBoundingClientRect()
    const rightMargin = window.innerWidth - rect.right
    if (rightMargin < 30) {
      datepicker.classList.add('is-right')
    } else if (datepicker.classList.contains('is-right')) {
      datepicker.classList.remove('is-right')
    }
  }
  
  disconnect() {
    this.inputTargets.forEach(input => {
      input.bulmaCalendar.destroy()
    })
  }
}
  
